import ApiService from "./ApiService";
import ClientAPI from "./ClientAPI";
export default class CustomDataService extends ApiService {
  async getdata(getDealsRequest: {
    filename: string;
    requestSource: string;
    ids: string;
  }) {
    const { filename, requestSource, ids } = getDealsRequest;
    const params = {
      filename,
      ids,
      requestSource,
    };

    return ClientAPI.Post(`customData/search`, params);
  }
  async postdata(postDealRequest: {
    filename: string;
    requestSource: string;
    record: any;
    oldRecord?: any;
  }) {
    const { filename, record, oldRecord, requestSource } = postDealRequest;
    const params = {
      filename,
      record,
      oldRecord,
      requestSource,
    };

    return ClientAPI.Post(`customData/post`, params);
  }

  async getitem(getDealRequest: {
    filename: string;
    requestSource: string;
    id: string;
  }) {
    const { filename, requestSource, id } = getDealRequest;
    const params = {
      filename,
      id,
      requestSource,
    };
    return ClientAPI.Get(`customData/Get`, params);
  }

  async getDownloadPdf(getDownloadPdfRequest: {
    filename: string;
    requestSource: string;
    id: string;
    pdfFormName?: string;
  }) {  
    const { filename, requestSource, id, pdfFormName } = getDownloadPdfRequest;
    const params = {
      filename,
      id,
      requestSource,
      pdfFormName
    };

    return ClientAPI.Get(`customData/pdf`, params);
  }
}

